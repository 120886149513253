/* from https://dev.to/madsstoumann/using-a-single-input-for-one-time-code-352l */
.one-time-input {
  --otp-digits: 6;
  --otp-ls: 2ch;
  --otp-gap: 1.25;

  /* private consts */
  --_otp-bgsz: calc(var(--otp-ls) + 1ch);

  all: unset;
  outline: none !important;
  outline-width: 0 !important;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  background: linear-gradient(90deg, var(--otp-bg, #eee) calc(var(--otp-gap) * var(--otp-ls)), transparent 0),
    linear-gradient(90deg, var(--otp-bg, #eee) calc(var(--otp-gap) * var(--otp-ls)), transparent 0);
  background-repeat: no-repeat, repeat-x;
  background-size: var(--_otp-bgsz) 100%;
  caret-color: var(--otp-cc, #222);
  clip-path: inset(0% calc(var(--otp-ls) / 2 + 4px) 0% 0%);
  -webkit-clip-path: inset(0% calc(var(--otp-ls) / 2 + 4px) 0% 0%);
  font-family: ui-monospace, monospace;
  font-size: var(--otp-fz, 2em);
  inline-size: calc(var(--otp-digits) * var(--_otp-bgsz));
  letter-spacing: var(--otp-ls);
  padding-block: var(--otp-pb, 0.75ch);
  padding-right: -10px;
  padding-inline-start: calc(((var(--otp-ls) - 1ch) / 2) * var(--otp-gap) + 2px);
  transform: scale(0.9);
  @media (max-width: 600px) {
    transform: scale(0.8);
    margin-left: -2rem;
  }
}
