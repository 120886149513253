.main {
    position: relative;
    border-radius: 50%;
    width: 16rem;
    height: 16rem;
}

.circle {
    position: absolute;
    left: calc(50% - 50px);
    top: calc(50% - 50px);
    height: 100px;
    width: 100px;
    background: red;
    border-radius: 50%;
}

.circle:nth-child(1) {
    transform: translateX(8rem);
}

.circle:nth-child(2) {
    transform: rotate(60deg) translateX(8rem);
    
}

.circle:nth-child(3){
    transform: rotate(120deg) translateX(8rem);
}

.circle:nth-child(4) {
    transform: rotate(180deg) translateX(8rem);
}

.circle:nth-child(5) {
    transform: rotate(240deg) translateX(8rem);
}

.circle:nth-child(6) {
    transform: rotate(300deg) translateX(8rem);
}

.circle:nth-child(6) > .adjuster {
    transform: rotate(-300deg) ;
}

.circle:nth-child(5) > .adjuster {
    transform: rotate(-240deg)
}

.circle:nth-child(4) > .adjuster {
    transform: rotate(-180deg)
}

.circle:nth-child(3) > .adjuster {
    transform: rotate(-120deg)
}

.circle:nth-child(2) > .adjuster{
    transform: rotate(-60deg)
}
